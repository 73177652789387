import React from 'react'
import { Card, Placeholder, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

let UsersTable = (props) => {
   
    let navigate = useNavigate();
    const routeChange = (userId) =>{
      let path = `user/${userId}`;
      navigate(path);
    }

    return (
        <div>
            <Placeholder as={Card.Text} animation="glow">
                <Table hover size="sm">
                    <thead>
                        <tr>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(1)}}>Имя</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(2)}}>Фамилия</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(3)}}>Никнейм</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(4)}}>E-mail</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(5)}}>Должность</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(6)}}>День рождения</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(7)}}>Последняя активность</th>
                            <th className='title-element' onClick={(e) => {props.onDropdownClick(8)}}>Дата регистрации</th>
                            <th className='title-element'>Количество задач</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.users.map(m =>
                                <tr className='user-ref' onClick={(e) => {routeChange(m.userId)}} key={m.userId}>
                                    <td>{m.name}</td>
                                    <td>{m.lastname}</td>
                                    <td>{m.nickname}</td>
                                    <td>{m.email}</td>
                                    <td>{m.position}</td>
                                    <td>{props.getDate(m.birthday)}</td>
                                    <td>{props.getLastActivity(m.lastActivity)}</td>
                                    <td>{props.getDate(m.registrationDate)}</td>
                                    <td>{m.tasksCount}</td>
                                </tr>                             
                            )
                        }
                    </tbody>
                </Table>
            </Placeholder>
        </div>
    );
}

export default UsersTable;
