import React from "react";
import { connect } from "react-redux";
import { setAsc, setCurrentPage, setSearchString, setTotalUsersCount, setUsers, toggleIsFetching, updateSearchString, updateUsersSortType } from "../../redux/users-reducer";
import Users from "./Users";
import requestAdmin from "../../requests/requestAdmin";

class UsersApiComponent extends React.Component {
    componentDidMount() {
        this.props.toggleIsFetching(true);
        requestAdmin.get(`Admin/Users?page=${this.props.currentPage}`
                +`&count=${this.props.pageSize}`
                +`&usersSortType=${this.props.usersSortType}`
                +`&isAsc=${this.props.isAsc}`
                +`&timeZone=${localStorage.getItem('timeZone')}`).then(response => {
            this.props.setUsers(response.data.users);
            this.props.setTotalUsersCount(response.data.totalUsers);
            this.props.toggleIsFetching(false);
        });
    }

    onPageChanged = (p) => {
        this.props.setCurrentPage(p);
        this.props.toggleIsFetching(true);
        requestAdmin.get(`Admin/Users?search=${this.props.searchString}`
                +`&page=${p}`
                +`&count=${this.props.pageSize}`
                +`&usersSortType=${this.props.usersSortType}`
                +`&isAsc=${this.props.isAsc}`
                +`&timeZone=${localStorage.getItem('timeZone')}`).then(response => {
            this.props.toggleIsFetching(false);
            this.props.setUsers(response.data.users);
        });
    }

    search = (type) => {
        this.props.setCurrentPage(1);
        let locIsAsc = true;
        if (this.props.usersSortType === type) {
            locIsAsc = !this.props.isAsc;
            this.props.setAsc(!this.props.isAsc);
        } else {
            this.props.setAsc(true);
            locIsAsc = true;
        }
        this.props.updateUsersSortType(type);
        this.props.toggleIsFetching(true);
        requestAdmin.get(`Admin/Users?search=${this.props.searchString}`
                +`&page=${1}&count=${this.props.pageSize}`
                +`&usersSortType=${type}`
                +`&isAsc=${locIsAsc}`
                +`&timeZone=${localStorage.getItem('timeZone')}`).then(response => {
            this.props.toggleIsFetching(false);
            this.props.setUsers(response.data.users);
            this.props.setTotalUsersCount(response.data.totalUsers);
        });
    }

    cancel = () => {
        this.props.toggleIsFetching(true);
        this.props.setCurrentPage(1);
        this.props.updateUsersSortType(0);
        this.props.setAsc(true);
        this.props.setSearchString("");
        requestAdmin.get(`Admin/Users?page=${this.props.currentPage}`
                +`&count=${this.props.pageSize}`
                +`&usersSortType=${this.props.usersSortType}`
                +`&isAsc=${this.props.isAsc}`
                +`&timeZone=${localStorage.getItem('timeZone')}`).then(response => {
            this.props.setUsers(response.data.users);
            this.props.setTotalUsersCount(response.data.totalUsers);
            this.props.toggleIsFetching(false);
        });
    }
    render() {
        return <>
            <Users
                usersSortTypeArray={this.props.usersSortTypeArray}
                usersSortType={this.props.usersSortType}
                updateUsersSortType={this.props.updateUsersSortType}
                updateSearchString={this.props.updateSearchString}
                searchString={this.props.searchString}
                search={this.search}
                users={this.props.users}
                pageSize={this.props.pageSize}
                currentPage={this.props.currentPage}
                onPageChanged={this.onPageChanged}
                totalUsers={this.props.totalUsers}
                isFetching={this.props.isFetching}
                cancel={this.cancel}
            />
        </>

    }
}

let mapStateToProps = (state) => {
    return {
        usersSortType: state.usersPage.usersSortType,
        users: state.usersPage.users,
        pageSize: state.usersPage.pageSize,
        totalUsers: state.usersPage.totalUsers,
        currentPage: state.usersPage.currentPage,
        isFetching: state.usersPage.isFetching,
        searchString: state.usersPage.searchString,
        usersSortTypeArray: state.usersPage.usersSortTypeArray,
        isAsc: state.usersPage.isAsc,
    }
}

let mapDispatchToStore = (dispatch) => {
    return {
        setUsers: (users) => {
            dispatch(setUsers(users));
        },
        setCurrentPage: (pageNumber) => {
            dispatch(setCurrentPage(pageNumber));
        },
        setTotalUsersCount: (totalCount) => {
            dispatch(setTotalUsersCount(totalCount));
        },
        toggleIsFetching: (isFetching) => {
            dispatch(toggleIsFetching(isFetching));
        },
        updateSearchString: (searchString) => {
            dispatch(updateSearchString(searchString));
        },
        updateUsersSortType: (usersSortType) => {
            dispatch(updateUsersSortType(usersSortType))
        },
        setSearchString: (searchString) => {
            dispatch(setSearchString(searchString))
        },
        setAsc: (isAsc) => {
            dispatch(setAsc(isAsc))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToStore)(UsersApiComponent);