const SET_ADMIN = "SET_ADMIN";


let initialState = {
    admins: [],
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADMIN:
            return {
                ...state,
                admins: action.admins
            }
        default:
            return state;
    }
}

export const setAdmin = (admins) => {
    return {
        type: SET_ADMIN,
        admins: admins
    };
}


export default adminReducer;