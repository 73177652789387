import React, { useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteAdmin, getEditAdmin, resetPassword, saveChange } from '../../../asyncAction/admin';
import { setEmail, setFirstName, setLastName } from '../../../redux/adminEdit-reducer';
import './AdminEditPage.css'

const AdminEditPage = () => {
    const dispatch = useDispatch();

    const { id } = useParams();

    const firstName = useSelector(state => state.adminEditPage.firstName);
    const lastName = useSelector(state => state.adminEditPage.lastName);
    const fullName = useSelector(state => state.adminEditPage.fullName);
    const email = useSelector(state => state.adminEditPage.email);
    const isLoading = useSelector(state => state.adminEditPage.isLoading);

    useEffect(() => {
        dispatch(getEditAdmin(id));
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    let onFirstNameChange = (e) => {
        dispatch(setFirstName(e.target.value));
    }

    let onLastNameChange = (e) => {
        dispatch(setLastName(e.target.value));
    }

    let onEmailChange = (e) => {
        dispatch(setEmail(e.target.value));
    }

    let onResetPasswor = (email) => {
        dispatch(resetPassword(email));
    }

    let onSaveChange = () => {
        dispatch(saveChange(firstName, lastName, email, id));
    }

    let onDelete = () => {
        dispatch(deleteAdmin(id)).then(routeChange)
    }

    let navigate = useNavigate();

    const routeChange = () => {
        navigate(`/admin`);
    }

    return (
        <div>
            {
                isLoading ?
                    <Spinner animation="border" className='loader' />
                    :
                    <div className='admin__page__content'>
                        <h3>{fullName}</h3>
                        <div className='admin__input'>
                            <input type='firstName'
                                id='firstName'
                                name='firstName'
                                value={firstName}
                                onChange={onFirstNameChange}
                                placeholder='Имя'
                                required
                                className='register-form__input'>
                            </input>

                            <input type='lastName'
                                id='lastName'
                                name='lastName'
                                value={lastName}
                                onChange={onLastNameChange}
                                placeholder='Фамилия'
                                className='register-form__input'>
                            </input>

                            <input type='email'
                                id='email'
                                name='email'
                                value={email}
                                onChange={onEmailChange}
                                placeholder='email'
                                className='register-form__input'>
                            </input>
                            <Button variant="primary" className='btn_edit_admin' onClick={onSaveChange}>
                                Сохранить
                            </Button>

                            <Button variant="warning" className='btn_edit_admin' onClick={() => onResetPasswor(email)}>
                                Сбросить пароль
                            </Button>
                            <Button variant="danger" onClick={onDelete}>
                                Удалить
                            </Button>
                        </div>
                    </div>
            }
        </div>
    )
}

export default AdminEditPage
