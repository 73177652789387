const SET_CREATE_ADMIN_NAME = "SET_CREATE_ADMIN_NAME";
const SET_CREATE_ADMIN_LAST_NAME = "SET_CREATE_ADMIN_LAST_NAME";
const SET_CREATE_ADMIN_EMAIL = "SET_CREATE_ADMIN_EMAIL";
const SET_CREATE_ADMIN_PASSWORD = "SET_CREATE_ADMIN_PASSWORD";
const SET_CREATE_ADMIN_TYPE = "SET_CREATE_ADMIN_TYPE";

let initialState = {
    createAdminName: "",
    createAdminLastName: "",
    createAdminEmail: "",
    createAdminPassword: "",
    createAdminType: 1
}

const createAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATE_ADMIN_EMAIL:
            return {
                ...state,
                createAdminEmail: action.createAdminEmail
            }
        case SET_CREATE_ADMIN_PASSWORD:
            return {
                ...state,
                createAdminPassword: action.createAdminPassword
            }
        case SET_CREATE_ADMIN_TYPE:
            return {
                ...state,
                createAdminType: action.createAdminType
            }
        case SET_CREATE_ADMIN_NAME:
            return {
                ...state,
                createAdminName: action.createAdminName
            }
        case SET_CREATE_ADMIN_LAST_NAME:
            return {
                ...state,
                createAdminLastName: action.createAdminLastName
            }
        default:
            return state;
    }
}

export const setCreateAdminName = (createAdminName) => {
    return {
        type: SET_CREATE_ADMIN_NAME,
        createAdminName: createAdminName
    };
}

export const setCreateAdminLastName = (createAdminLastName) => {
    return {
        type: SET_CREATE_ADMIN_LAST_NAME,
        createAdminLastName: createAdminLastName
    };
}

export const setCreateAdminEmail = (createAdminEmail) => {
    return {
        type: SET_CREATE_ADMIN_EMAIL,
        createAdminEmail: createAdminEmail
    };
}

export const setCreateAdminPassword = (createAdminPassword) => {
    return {
        type: SET_CREATE_ADMIN_PASSWORD,
        createAdminPassword: createAdminPassword
    };
}

export const setCreateAdminType = (createAdminType) => {
    return {
        type: SET_CREATE_ADMIN_TYPE,
        createAdminType: createAdminType
    };
}

export default createAdminReducer;