import React from 'react'
import './Statistics.css'
import { Dropdown } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Statistics = (props) => {
    console.log(props);
    let onDropdownClick = (type) => {
        props.onTypeChanged(type)
    }

    let getDate = (date) => {
        if(date == null){
            return "";
        }
        let dateWithoutSec = date.split('T')[0];
        return dateWithoutSec.split('-')[2]
            + '.'
            + dateWithoutSec.split('-')[1]
            + '.'
            + dateWithoutSec.split('-')[0];
    }

    let navigate = useNavigate();
    const routeChange = (userId) =>{
      let path = "/user/" + userId;
      navigate(path);
    }

    return (
        <div className='statistics-wrapper'>
            Количество новых пользователей 
            <Dropdown className="d-inline mx-2 my-dropdown">
                <Dropdown.Toggle id="dropdown-custom">
                    {props.statisticsTypeArray[props.statisticsType]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={(e) => { onDropdownClick(1); }}>за сегодня</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { onDropdownClick(2); }}>за неделю</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { onDropdownClick(3); }}>за месяц</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => { onDropdownClick(4); }}>за год</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <br/>
            Новых пользователей: {props.totalUsers}

            <Table hover size="sm">
                    <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Фамилия</th>
                            <th>Почта</th>
                            <th>Дата регистрации</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.users.map(m =>
                                <tr className='user-ref' onClick={(e) => {routeChange(m.userId)}} key={m.id}>
                                    <td>{m.name}</td>
                                    <td>{m.lastname}</td>
                                    <td>{m.email}</td>
                                    <td>{getDate(m.registrationDate)}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
        </div>
    )
}

export default Statistics
