import React from 'react'
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { setUser, setName, setLastname, setNickname, setEmail, setPosition, setHistory,  setAdminEmail, setAdminPassword } from '../../redux/user-reducer';
import requestAdmin from "../../requests/requestAdmin";
import User from './User';

class UserApiComponent extends React.Component {
    componentDidMount() {
        this.getUser();
        this.getHistory();
    }

    getHistory = () => {
        let userId = this.props.router.params.userId;
        requestAdmin.get(`History/` + userId)
            .then(response => {
                this.props.setHistory(response.data);
            })
    }

    getUser = () => {
        let userId = this.props.router.params.userId;
        requestAdmin.get(`Admin/User?UserId=` + userId)
            .then(response => {
                this.props.setUser(response.data);

                if (response.data.name === null) {
                    this.props.setName("");
                } else {
                    this.props.setName(response.data.name);
                }

                if (response.data.lastname === null) {
                    this.props.setLastname("");
                } else {
                    this.props.setLastname(response.data.lastname);
                }

                if (response.data.nickname === null) {
                    this.props.setNickname("");
                } else {
                    this.props.setNickname(response.data.nickname);
                }


                if (response.data.email === null) {
                    this.props.setEmail("");
                } else {
                    this.props.setEmail(response.data.email);
                }

                if (response.data.position === null) {
                    this.props.setPosition("");
                } else {
                    this.props.setPosition(response.data.position);
                }
            })
    }

    saveUser = () => {
        let userId = this.props.router.params.userId;
        requestAdmin.put(`Admin/User`, {
            userId: userId,
            name: this.props.name,
            lastname: this.props.lastname,
            nickname: this.props.nickname,
            email: this.props.email,
            position: this.props.position
        }).then(response => {
            this.props.setUser(response.data);
            this.props.setName(response.data.name);
            this.props.setLastname(response.data.lastname);
            this.props.setNickname(response.data.nickname);
            this.props.setEmail(response.data.email);
            this.props.setPosition(response.data.position);
            this.getHistory();
        });
    }


    cancelHistory = (historyId) => {
        requestAdmin.put(`History`, {
            historyId: historyId,
        }).then(response => {
            this.getHistory();
            this.getUser();
        });
    }

    render() {
        return (
            <div>
                <User user={this.props.user}
                    name={this.props.name}
                    lastname={this.props.lastname}
                    nickname={this.props.nickname}
                    email={this.props.email}
                    position={this.props.position}
                    setName={this.props.setName}
                    setLastname={this.props.setLastname}
                    setNickname={this.props.setNickname}
                    setEmail={this.props.setEmail}
                    setPosition={this.props.setPosition}
                    saveUser={this.saveUser}
                    history={this.props.history}
                    cancelHistory={this.cancelHistory}
                    adminEmail={this.props.adminEmail}
                    adminPassword={this.props.adminPassword}
                    setAdminEmail={this.props.setAdminEmail}
                    setAdminPassword={this.props.setAdminPassword} />
            </div>
        )
    }
}

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component {...props} router={{ location, navigate, params }} />
        );
    }

    return ComponentWithRouterProp;
}

let mapStateToProps = (state) => {
    return {
        adminEmail: state.userPage.adminEmail,
        adminPassword: state.userPage.adminPassword,
        user: state.userPage.user,
        name: state.userPage.name,
        lastname: state.userPage.lastname,
        nickname: state.userPage.nickname,
        email: state.userPage.email,
        position: state.userPage.position,
        history: state.userPage.history,
    }
}

export default compose(
    connect(mapStateToProps, { setUser, setName, setLastname, setNickname, setEmail, setPosition, setHistory, setAdminEmail, setAdminPassword }),
    withRouter
)(UserApiComponent);