const SET_USERS = "SET_USERS";
const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
const TOGGLE_IS_FTCHING = "TOGGLE_IS_FTCHING";
const UPDATE_STATISTICS_TYPE= "UPDATE_STATISTICS_TYPE";

let initialState = {
    users: [],
    totalUsers: 0,
    isFetching: false,
    statisticsType: 1,
    statisticsTypeArray: ["", "за сегодня", "за неделю", "за месяц", "за год"]
}

const statisticsReduser = (state = initialState, action) => {
    switch(action.type){
        case SET_USERS:
            return {
                ...state,
                users: action.users
            }
        case SET_TOTAL_COUNT:
            return {
                ...state,
                totalUsers: action.totalUsers
            }
        case TOGGLE_IS_FTCHING:
            return {
                ...state,
                isFetching: action.isFetching
            }
        case UPDATE_STATISTICS_TYPE:
            return{
                ...state,
                statisticsType: action.statisticsType
            }
        default:
            return state;
    }
}

export const setUsers = (users) => {
    return {
        type: SET_USERS,
        users: users
    };
}

export const setTotalUsersCount = (totalUsers) => {   
    return {
        type: SET_TOTAL_COUNT,
        totalUsers: totalUsers
    };
}

export const toggleIsFetching = (isFetching) => {
    return {
        type: TOGGLE_IS_FTCHING,
        isFetching: isFetching
    };
}

export const updateStatisticsType = (statisticsType) => {
    return {
        type: UPDATE_STATISTICS_TYPE,
        statisticsType: statisticsType
    };
}

export default statisticsReduser;
