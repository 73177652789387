import React from "react";
import { connect } from "react-redux";
import { setTotalUsersCount, setUsers, toggleIsFetching, updateStatisticsType } from "../../redux/statistics-reduser";
import Statistics from "./Statistics";
import requestAdmin from "../../requests/requestAdmin";

class StatisticsApiComponent extends React.Component {
    componentDidMount(){
        this.props.toggleIsFetching(true);
        requestAdmin.get(`Admin/Statistics?AdminStatisticType=${this.props.statisticsType}`).then(response => {
            this.props.setUsers(response.data.users);
            this.props.setTotalUsersCount(response.data.totalUsers);
            this.props.toggleIsFetching(false);
        });
    }

    onTypeChanged = (type) => {
        this.props.updateStatisticsType(type);
        this.props.toggleIsFetching(true);
        requestAdmin.get(`Admin/Statistics?AdminStatisticType=${type}`).then(response => {
            this.props.toggleIsFetching(false);
            this.props.setUsers(response.data.users);
            this.props.setTotalUsersCount(response.data.totalUsers);
            this.props.updateStatisticsType(type);
        });
    }

    render() {
        return <>
            <Statistics
                users={this.props.users}
                statisticsTypeArray = {this.props.statisticsTypeArray}
                totalUsers={this.props.totalUsers}
                isFetching={this.props.isFetching}
                statisticsType = {this.props.statisticsType}
                setUsers = {this.props.setUsers}
                setTotalUsersCount = {this.props.setTotalUsersCount}
                toggleIsFetching = {this.props.toggleIsFetching}
                updateStatisticsType={this.props.updateStatisticsType}
                onTypeChanged={this.onTypeChanged}
            />
        </>

    }
}

let mapStateToProps = (state) => {
    return {
        users: state.statisticsPage.users,
        statisticsType: state.statisticsPage.statisticsType,
        totalUsers: state.statisticsPage.totalUsers,
        isFetching: state.statisticsPage.isFetching,
        statisticsTypeArray: state.statisticsPage.statisticsTypeArray
    }
}

let mapDispatchToStore = (dispatch) => {
    return {
        setUsers: (users) => {
            dispatch(setUsers(users));
        },
        setTotalUsersCount: (totalCount) => {
            dispatch(setTotalUsersCount(totalCount));
        },
        toggleIsFetching: (isFetching) => {
            dispatch(toggleIsFetching(isFetching));
        },
        updateStatisticsType: (statisticsType) => {
            dispatch(updateStatisticsType(statisticsType))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToStore)(StatisticsApiComponent);
