import './Users.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from 'react-bootstrap/Pagination';
import { Dropdown } from 'react-bootstrap';
import PlaceholderTable from './PlaceholderTable';
import UsersTable from './UsersTable';
import { Button } from 'react-bootstrap';
import { getCsvUsers } from '../../asyncAction/admin';

const Users = (props) => {
    let pagesCount = Math.ceil(props.totalUsers / props.pageSize);
    let pages = [];

    if (pagesCount >= 9) {
        if (props.currentPage < 5) {
            for (let i = 1; i <= 9; i++) {
                pages.push(
                    <Pagination.Item onClick={(e) => { props.onPageChanged(i); }} key={i} active={i === props.currentPage}>
                        {i}
                    </Pagination.Item>
                );
            }
        } else if (props.currentPage + 5 > pagesCount) {
            for (let i = pagesCount - 8; i <= pagesCount; i++) {
                pages.push(
                    <Pagination.Item onClick={(e) => { props.onPageChanged(i); }} key={i} active={i === props.currentPage}>
                        {i}
                    </Pagination.Item>
                );
            }
        } else {
            for (let i = props.currentPage - 4; i <= props.currentPage + 4; i++) {
                pages.push(
                    <Pagination.Item onClick={(e) => { props.onPageChanged(i); }} key={i} active={i === props.currentPage}>
                        {i}
                    </Pagination.Item>
                );
            }
        }
    } else {
        for (let i = 1; i <= pagesCount; i++) {
            pages.push(
                <Pagination.Item onClick={(e) => { props.onPageChanged(i); }} key={i} active={i === props.currentPage}>
                    {i}
                </Pagination.Item>
            );
        }
    }

    let getDate = (date) => {
        if (date == null) {
            return "";
        }
        let dateWithoutSec = date.split('T')[0];
        return dateWithoutSec.split('-')[2]
            + '.'
            + dateWithoutSec.split('-')[1]
            + '.'
            + dateWithoutSec.split('-')[0];
    }
    let getLastActivity = (date) => {
        if (date == null) {
            return "";
        }

        let dateWithoutSec = date.split('T')[0];
        let time = date.split('T')[1];
        return dateWithoutSec.split('-')[2]
            + '.'
            + dateWithoutSec.split('-')[1]
            + '.'
            + dateWithoutSec.split('-')[0]
            + ' '
            + time.split('.')[0].split(':')[0]
            + ':'
            + time.split('.')[0].split(':')[1];
    }
    let onKeyDown = e => {
        if (e.keyCode === 13) {
            props.search(props.usersSortType)
        }
    }

    let onUpdateSearchString = (e) => {
        let string = e.target.value;
        props.updateSearchString(string);
    }

    let onDropdownClick = (type) => {
        props.search(type)
    }

    let onCancel = () => {
        props.cancel()
    }

    let downloadCsv = () => {
        getCsvUsers();
    }

    return (
        <div className='users_content'>
            <div className='action-block'>
                <div className='search-block'>
                    <input type='search'
                        placeholder='Поиск...'
                        className='search_string'
                        onKeyDown={onKeyDown}
                        value={props.searchString}
                        onChange={onUpdateSearchString} />
                    <div className='cancel-btn-block'>
                        <p className='cancel-btn' onClick={(e) => { onCancel(); }}>очистить</p>
                    </div>
                </div>
                <div className='sort'>
                    <Button onClick={downloadCsv}>Скачать CSV</Button>
                    <Dropdown className="sort d-inline mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            {props.usersSortTypeArray[props.usersSortType]}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(1); }}>По имени</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(2); }}>По фамилии</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(3); }}>По nickname</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(4); }}>По e-mail</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(5); }}>По должности</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(6); }}>По дате рождения</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(7); }}>По последней активности</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { onDropdownClick(8); }}>По дате регистрации</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='table'>
                {props.isFetching ? <PlaceholderTable /> : <UsersTable users={props.users} getDate={getDate} getLastActivity={getLastActivity} onDropdownClick={onDropdownClick} />}
            </div>
            <div>
                <Pagination>
                    <Pagination.First onClick={(e) => { props.onPageChanged(1); }} />
                    <Pagination.Prev onClick={(e) => { props.onPageChanged(props.currentPage - 1 >= 1 ? props.currentPage - 1 : 1); }} />
                    {pages}
                    <Pagination.Next onClick={(e) => { props.onPageChanged(props.currentPage + 1 <= pagesCount ? props.currentPage + 1 : pagesCount); }} />
                    <Pagination.Last onClick={(e) => { props.onPageChanged(pagesCount); }} />
                </Pagination>
            </div>
        </div>
    )
}

export default Users;