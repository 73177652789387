import React, { useState } from 'react'
import './Test.css'

const Test = () => {
    let [text, setText] = useState("asds");
    let [textInput, setTextInput] = useState("");
    let [priority, setPriority] = useState(0);

    let onChangeText = (e) => {
        if(text === "p1"){
            console.log("p = 1");
            setPriority(1);
            setText(() => {return <div className='priority-active'>{text + "s"}</div>});
        }
        setTextInput("");
        setText(text + e.target.value);
    }

    let handleKeyPress = (event) => {
        var key = event.keyCode || event.charCode;
        if (key === 8) {
            let newText = text.slice(0, -1);
            setText(newText);
        }
    }

    let onP = (event) => {
        console.log(event);
    }

    return (
        <div style={{ margin: '100px' }}>
            {text}
            <br></br>
            <div className='text-div'>{text}<input className='text-input' type="text" value={textInput} onChange={onChangeText} onKeyDown={handleKeyPress} /></div>
            <br />
            Приоритет = {priority}
            <div contentEditable="true" onKeyDown={onP}>
                <p value={text}  onKeyDown={onP}>{text} </p>
            </div>
        </div>
    )
}

export default Test
