const SET_USER = "SET_USER";
const SET_NAME = "SET_NAME";
const SET_LASTNAME = "SET_LASTNAME";
const SET_NICKNAME = "SET_NICKNAME";
const SET_EMAIL = "SET_EMAIL";
const SET_POSITION = "SET_POSITION";
const SET_HISTORY = "SET_HISTORY";
const SET_ADMIN_EMAIL = "SET_ADMIN_EMAIL";
const SET_ADMIN_PASSWORD = "SET_ADMIN_PASSWORD";

let initialState = {
    adminEmail: "",
    adminPassword: "",
    user: "",
    name: "",
    lastname: "",
    nickname: "",
    email: "",
    position: "",
    history: []
}

const userReduser = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        case SET_NAME:
            return {
                ...state,
                name: action.name
            }
        case SET_LASTNAME:
            return {
                ...state,
                lastname: action.lastname
            }
        case SET_NICKNAME:
            return {
                ...state,
                nickname: action.nickname
            }
        case SET_EMAIL:
            return {
                ...state,
                email: action.email
            }
        case SET_POSITION:
            return {
                ...state,
                position: action.position
            }
        case SET_HISTORY:
            return {
                ...state,
                history: action.history
            }
        case SET_ADMIN_EMAIL:
            return {
                ...state,
                adminEmail: action.adminEmail
            }
        case SET_ADMIN_PASSWORD:
            return {
                ...state,
                adminPassword: action.adminPassword
            }
        default:
            return state;
    }
}

export const setUser = (user) => {
    return {
        type: SET_USER,
        user: user
    };
}

export const setName = (name) => {
    return {
        type: SET_NAME,
        name: name
    };
}
export const setLastname = (lastname) => {
    return {
        type: SET_LASTNAME,
        lastname: lastname
    };
}
export const setNickname = (nickname) => {
    return {
        type: SET_NICKNAME,
        nickname: nickname
    };
}
export const setEmail = (email) => {
    return {
        type: SET_EMAIL,
        email: email
    };
}
export const setPosition = (position) => {
    return {
        type: SET_POSITION,
        position: position
    };
}

export const setHistory = (history) => {
    return {
        type: SET_HISTORY,
        history: history
    };
}

export const setAdminEmail = (adminEmail) => {
    return {
        type: SET_ADMIN_EMAIL,
        adminEmail: adminEmail
    };
}

export const setAdminPassword = (adminPassword) => {
    return {
        type: SET_ADMIN_PASSWORD,
        adminPassword: adminPassword
    };
}

export default userReduser;
