import React from 'react'
import './Header.css'
import logo from '../../icons/svg/logo_timetask.svg';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = () => {
    let navigate = useNavigate();

    let out = () => {
        localStorage.removeItem('token');
        navigate('/login', { require: true });

    }
    return (
        <div>
            <header id="Head" className="header">
                <div className="header__logo_block">
                    <img className="header__logo" alt="logo" src={logo}></img>
                    <span className="header__title">.<b>Admin</b></span>
                </div>
                <nav className='nav'>
                    <div className='item'>
                        <NavLink to='/'>Пользователи</NavLink>
                    </div>
                    <div className='item'>
                        <NavLink to='/statistics'>Статистика</NavLink>
                    </div>
                    <div className='item'>
                        <NavLink to='/history'>История</NavLink>
                    </div>
                    <div className='item'>
                        <NavLink to='/admin'>Администраторы</NavLink>
                    </div>
                </nav>

                <button className='btn-out' onClick={out}>Выход</button>
            </header>
        </div>
    )
}

export default Header
