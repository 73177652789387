const SET_HISTORY = "SET_HISTORY";
const ADD_HISTORY = "ADD_HISTORY";

let initialState = {
    history: []
}

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HISTORY:
            return {
                ...state,
                history: action.history
            }
        case ADD_HISTORY:
            return {
                ...state,
                history: [...state.history, action.payload]
            }
        default:
            return state;
    }
}

export const setHistory = (history) => {
    return {
        type: SET_HISTORY,
        history: history
    };
}

export const addHistory = (payload) => ({type: ADD_HISTORY, payload});


export default historyReducer;
