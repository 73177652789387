import React from 'react'
import { Form } from 'react-bootstrap'
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createAdmin } from '../../../asyncAction/admin';
import { setCreateAdminEmail, setCreateAdminLastName, setCreateAdminName, setCreateAdminPassword, setCreateAdminType } from '../../../redux/createAdmin-reducer';

const AdminCreateModal = (props) => {
    const dispatch = useDispatch();
    const type = useSelector(state => state.createAdminPage.createAdminType);
    const name = useSelector(state => state.createAdminPage.createAdminName);
    const lastName = useSelector(state => state.createAdminPage.createAdminLastName);
    const email = useSelector(state => state.createAdminPage.createAdminEmail);
    const password = useSelector(state => state.createAdminPage.createAdminPassword);

    let onClickBtn1 = () => {
        dispatch(setCreateAdminType(1));
    }

    let onClickBtn2 = () => {
        dispatch(setCreateAdminType(2));
    }

    let onEmialChange = (e) => {
        dispatch(setCreateAdminEmail(e.target.value));
    }

    let onPasswordChange = (e) => {
        dispatch(setCreateAdminPassword(e.target.value));

    }

    let onNameChange = (e) => {
        dispatch(setCreateAdminName(e.target.value));
    }

    let onLastNameChange = (e) => {
        dispatch(setCreateAdminLastName(e.target.value));

    }

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(createAdmin(name, lastName, email, password, type));
        props.handleClose();
    }
    return (
        <div className='admin__page__content'>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавить нового администратора</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <input type='name'
                            id='name'
                            name='name'
                            value={name}
                            onChange={onNameChange}
                            placeholder='Имя'
                            className="register-form__input">
                        </input>

                        <input type='lastName'
                            id='lastName'
                            name='lastName'
                            value={lastName}
                            onChange={onLastNameChange}
                            placeholder='Фамилия'
                            className="register-form__input">
                        </input>

                        <input type='email'
                            id='email'
                            name='email'
                            value={email}
                            onChange={onEmialChange}
                            placeholder='E-mail'
                            className="register-form__input">
                        </input>

                        {type === 1 ?
                            <input type='password'
                                id='password'
                                name='password'
                                value={password}
                                onChange={onPasswordChange}
                                placeholder='Пароль'
                                className="register-form__input">
                            </input>
                            :
                            <div></div>
                        }

                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="Ввести пароль"
                                        name="group1"
                                        type={type}
                                        defaultChecked
                                        onClick={onClickBtn1}
                                        id={`inline-${type}-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="Сгенерировать пароль"
                                        name="group1"
                                        type={type}
                                        onClick={onClickBtn2}
                                        id={`inline-${type}-2`}
                                    />
                                </div>
                            ))}
                        </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Отменить
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Отправить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminCreateModal
