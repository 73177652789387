import React from 'react'
import { Card, Placeholder, Table } from 'react-bootstrap';

let PlaceholderTable = () => {
    return (
        <div>
            <Placeholder as={Card.Text} animation="glow">
                <Table hover size="sm">
                    <thead>
                        <tr>
                            <th>Имя</th>
                            <th>Фамилия</th>
                            <th>Никнейм</th>
                            <th>E-mail</th>
                            <th>Должность</th>
                            <th>День рождения</th>
                            <th>Последняя активность</th>
                            <th>Дата регистрации</th>
                            <th>Количество задач</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(Array(10).keys()).map(x => (
                            <tr key={x}>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                                <td><Placeholder xs={10} /></td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </Placeholder>
        </div >
    );
}

export default PlaceholderTable;
