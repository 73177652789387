import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEmail, setPassword } from '../../redux/login-reducer';
import requestAdmin from '../../requests/requestAdmin';
import './LoginPage.css'

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector(state => state.loginPage.email);
    const password = useSelector(state => state.loginPage.password);

    let onEmialChange= (e) => {
        let body = e.target.value;
        dispatch(setEmail(body));
    }

    let onPasswordChange= (e) => {
        let body = e.target.value;
        dispatch(setPassword(body));
    }

    function handleSubmit(e) {
        e.preventDefault();
        requestAdmin.post('/Account/Login', {
            email: email,
            password: password
        })
            .then(res => {
                if (res.data.errors) {
                    alert(res.data.errors);
                }
                if (res.data.token) {
                    console.log(res)
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('userId', res.data.userId);
                    localStorage.setItem('timeZone', res.data.timeZone);
                    navigate('/', {require: true});
                }
            })
    }
    return (
        <div className="register-form__page">
            <div className="register-form__container">
                <h2 className="register-form__heading">Вход в аккаунт</h2>
                <form onSubmit={handleSubmit} className='register-form__form'>
                    <input type='email'
                        id='email'
                        name='email'
                        value={email}
                        onChange={onEmialChange}
                        placeholder='E-mail'
                        className="register-form__input">
                    </input>
                    <input type='password'
                        id='password'
                        name='password'
                        value={password}
                        onChange={onPasswordChange}
                        placeholder='Пароль'
                        className="register-form__input">
                    </input>
                    <button onClick={() => handleSubmit} className="login-form__btn">Войти</button>
                </form>
            </div>

        </div>
    )
}

export default LoginPage