import { setHistory } from "../redux/history-reducer";
import requestAdmin from "../requests/requestAdmin"

export const getHistory = () => {
    return async dispatch => {
        await requestAdmin.get(`History`)
            .then(data => dispatch(setHistory(data.data)))
    }
}

export const cancelHistory = (historyId) => {
    return async dispatch => {
        await requestAdmin.put(`History`, {
            historyId: historyId
        })
        .then(data => dispatch(setHistory(data.data)))
    }
}