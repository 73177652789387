const SET_SP_PASSWORD = "SET_SP_PASSWORD";
const SET_SP_PASSWORD_CONFIRM = "SET_SP_PASSWORD_CONFIRM";

let initialState = {
    spPassword: "",
    spPasswordConfirm: "",
}

const setPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SP_PASSWORD:
            return {
                ...state,
                spPassword: action.spPassword
            }
        case SET_SP_PASSWORD_CONFIRM:
            return {
                ...state,
                spPasswordConfirm: action.spPasswordConfirm
            }
        default:
            return state;
    }
}

export const setSpPassword = (spPassword) => {
    return {
        type: SET_SP_PASSWORD,
        spPassword: spPassword
    };
}

export const setSpPasswordConfirm= (spPasswordConfirm) => {
    return {
        type: SET_SP_PASSWORD_CONFIRM,
        spPasswordConfirm: spPasswordConfirm
    };
}

export default setPasswordReducer;