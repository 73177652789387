import React from 'react'
import { Card, Placeholder, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

let AdminTable = (props) => {
   
    let navigate = useNavigate();
    const routeChange = (userId) =>{
      let path = `${userId}`;
      navigate(path);
    }

    return (
        <div>
            <Placeholder as={Card.Text} animation="glow">
                <Table hover size="sm">
                    <thead>
                        <tr>
                            <th className='title-element'>Имя</th>
                            <th className='title-element'>Фамилия</th>
                            <th className='title-element'>Почта</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.admins.map(m =>
                                <tr className='user-ref' onClick={(e) => {routeChange(m.userId)}} key={m.userId}>
                                    <td>{m.firstName}</td>
                                    <td>{m.lastName}</td>
                                    <td>{m.email}</td>
                                </tr>                             
                            )
                        }
                    </tbody>
                </Table>
            </Placeholder>
        </div>
    );
}

export default AdminTable;
