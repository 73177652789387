import React, { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap';
import requestAdmin from '../../requests/requestAdmin';
import './User.css'

const User = (props) => {
    let getLastActivity = (date) => {
        if (date == null) {
            return "";
        }

        let dateWithoutSec = date.split('T')[0];
        let time = date.split('T')[1];
        return dateWithoutSec.split('-')[2]
            + '.'
            + dateWithoutSec.split('-')[1]
            + '.'
            + dateWithoutSec.split('-')[0]
            + ' '
            + time.split('.')[0].split(':')[0]
            + ':'
            + time.split('.')[0].split(':')[1];
    }

    let onChangeName = (e) => {
        props.setName(e.target.value);
    }

    let onChangeLastname = (e) => {
        props.setLastname(e.target.value);
    }

    let onChangeNickname = (e) => {
        props.setNickname(e.target.value);
    }

    let onChangeEmail = (e) => {
        props.setEmail(e.target.value);
    }

    let onChangePosition = (e) => {
        props.setPosition(e.target.value);
    }

    let save = () => {
        props.saveUser();
        alert("Данные сохранены");
    }

    let getDate = (date) => {
        if (date == null) {
            return "";
        }

        let dateWithoutSec = date.split('T')[0];
        let time = date.split('T')[1];
        return dateWithoutSec.split('-')[2]
            + '.'
            + dateWithoutSec.split('-')[1]
            + '.'
            + dateWithoutSec.split('-')[0]
            + ' '
            + time.split('.')[0].split(':')[0]
            + ':'
            + time.split('.')[0].split(':')[1];
    }

    let cancelHistory = (id) => {
        props.cancelHistory(id);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let onEmialChange= (e) => {
        let body = e.target.value;
        props.setAdminEmail(body);
    }

    let onPasswordChange= (e) => {
        let body = e.target.value;
        props.setAdminPassword(body);
    }

    function handleSubmit(e) {
        e.preventDefault();
        requestAdmin.post('/Account/IsCorrectPassword', {
            email: props.adminEmail,
            password: props.adminPassword
        })
            .then(res => {
                console.log(res)
                if (res.data) {
                    save()
                    handleClose();
                }else{
                    alert("Не верно!");
                }
            })
    }

    return (
        <div className='user-page-container'>
            <div className="settings-page__container">
                <div className="settings-page__background">
                    <div className="settings-page__user-avatar"></div>
                </div>
            </div>
            <div className="settings-page__header">
                <h1 className="settings-page__title">{props.user.name} {props.user.lastname}</h1>
                <h1 className="settings-page__last-activity">Был в сети: {getLastActivity(props.user.lastActivity)}</h1>
            </div>
            <div className='user-page__data'>
                <div className='user-page__data-box'>
                    <div className='user-page__data-box__name'>Имя:</div>
                    <div className='user-page__data-box__value'>
                        <input type="text" value={props.name} onChange={onChangeName} />
                    </div>
                </div>
                <div className='user-page__data-box'>
                    <div className='user-page__data-box__name'>Фамилия:</div>
                    <div className='user-page__data-box__value'>
                        <input type="text" value={props.lastname} onChange={onChangeLastname} />
                    </div>
                </div>
                <div className='user-page__data-box'>
                    <div className='user-page__data-box__name'>Ник:</div>
                    <div className='user-page__data-box__value'>
                        <input type="text" value={props.nickname} onChange={onChangeNickname} />
                    </div>
                </div>
                <div className='user-page__data-box'>
                    <div className='user-page__data-box__name'>Почта:</div>
                    <div className='user-page__data-box__value'>
                        <input type="text" value={props.email} onChange={onChangeEmail} />
                    </div>
                </div>
                <div className='user-page__data-box'>
                    <div className='user-page__data-box__name'>Должность:</div>
                    <div className='user-page__data-box__value'>
                        <input type="text" value={props.position} onChange={onChangePosition} />
                    </div>
                </div>
                <br/>
                <Button variant="primary" onClick={handleShow}>
                    Сохранить
                </Button>
            </div>

            <div className='history_title'>История изменений</div>
            <Table hover size="sm">
                <thead>
                    <tr>
                        <th>Пользователь</th>
                        <th>Измение</th>
                        <th>Старое значение</th>
                        <th>Новое значение</th>
                        <th>Дата изменения</th>
                        <th>Отмена изменения</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.history.map(h =>
                            <tr key={h.id}>
                                <td>{h.userFullName}</td>
                                <td>{h.field}</td>
                                <td>{h.oldValue}</td>
                                <td>{h.newValue}</td>
                                <td>{getDate(h.date)}</td>
                                <td><button onClick={() => { cancelHistory(h.id) }}>Отменить</button></td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите изменения</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} className='register-form__form'>
                        <input type='email'
                            id='email'
                            name='email'
                            value={props.adminEmail}
                            onChange={onEmialChange}
                            placeholder='E-mail'
                            className="register-form__input">
                        </input>
                        <input type='password'
                            id='password'
                            name='password'
                            value={props.adminPassword}
                            onChange={onPasswordChange}
                            placeholder='Пароль'
                            className="register-form__input">
                        </input>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default User