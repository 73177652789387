import './App.css';
import Header from './component/Header/Header';
import UsersContainer from './component/Users/UsersContainer';
import { Route, Routes, useNavigate } from 'react-router-dom';
import StatisticContainer from './component/statistics/StatisticContainer';
import UserContainer from './component/User/UserContainer';
import Test from './component/Test/Test';
import NotFoundPage from './component/NotFoundPage/NotFoundPage';
import LoginPage from './component/LoginPage/LoginPage';
import SetPasswordContainer from './component/SetPassword/SetPasswordContainer';
import History from './component/History/History';
import AdminPage from './component/AdminPage/AdminPage';
import AdminEditPage from './component/AdminPage/AdminEditPage/AdminEditPage';

function App() {
  useNavigate();

  return (
    localStorage.getItem('token') ? (
      <>
        <div>
          <Header />
          <div className='content'>
            <Routes>
              <Route path="/" element={<UsersContainer />} />
              <Route path="/users" element={<UsersContainer />} />
              <Route path="/statistics" element={<StatisticContainer />} />
              <Route path="/history" element={<History />} />
              <Route path="/test" element={<Test />} />
              <Route path="/user/:userId" element={<UserContainer />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/admin/:id" element={<AdminEditPage />} />
              <Route path="/SetPassword/" element={<SetPasswordContainer />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </>
    ) : (
      <>
        <Routes>
          <Route path="/login/" element={<LoginPage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/SetPassword/" element={<SetPasswordContainer />} />
        </Routes>
      </>
    )
  );
}

export default App;