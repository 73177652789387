import { applyMiddleware, combineReducers, legacy_createStore as createStore} from 'redux'
import thunk from 'redux-thunk';
import adminReducer from './admin-reducer';
import adminEditReducer from './adminEdit-reducer';
import createAdminReducer from './createAdmin-reducer';
import historyReducer from './history-reducer';
import loginReducer from './login-reducer';
import setPasswordReducer from './setPassword-reducer';
import statisticsReduser from './statistics-reduser';
import userReduser from './user-reducer';
import usersReduser from "./users-reducer";

let reducers = combineReducers({
    usersPage: usersReduser,
    statisticsPage: statisticsReduser,
    userPage: userReduser,
    historyPage: historyReducer,
    loginPage: loginReducer,
    createAdminPage: createAdminReducer,
    setPasswordPage: setPasswordReducer,
    adminPage: adminReducer,
    adminEditPage: adminEditReducer
});

let store = createStore(reducers, applyMiddleware(thunk));

export default store;