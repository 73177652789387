const SET_FIRST_NAME = "SET_FIRST_NAME";
const SET_LAST_NAME = "SET_LAST_NAME";
const SET_FULL_NAME = "SET_FULL_NAME";
const SET_EMAIL = "SET_EMAIL";
const SET_IS_LOADING = "SET_IS_LOADING";

let initialState = {
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    isLoading: false
}

const adminEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.firstName
            }
        case SET_LAST_NAME:
            return {
                ...state,
                lastName: action.lastName
            }
        case SET_FULL_NAME:
            return {
                ...state,
                fullName: action.fullName
            }
        case SET_EMAIL:
            return {
                ...state,
                email: action.email
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}

export const setFirstName = (firstName) => {
    return {
        type: SET_FIRST_NAME,
        firstName: firstName
    };
}

export const setLastName = (lastName) => {
    return {
        type: SET_LAST_NAME,
        lastName: lastName
    };
}

export const setFullName = (fullName) => {
    return {
        type: SET_FULL_NAME,
        fullName: fullName
    };
}

export const setEmail = (email) => {
    return {
        type: SET_EMAIL,
        email: email
    };
}

export const setIsLoading= (payload) => ({ type: SET_IS_LOADING, payload });

export default adminEditReducer;