const SET_USERS = "SET_USERS";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
const TOGGLE_IS_FTCHING = "TOGGLE_IS_FTCHING";
const UPDATE_SEARCH_STRING = "UPDATE_SEARCH_STRING";
const UPDATE_USERS_SORT_TYPE = "UPDATE_USERS_SORT_TYPE";
const SET_SEARCH_STRING = "SET_SEARCH_STRING";
const SET_ASC = "SET_ASC";

let initialState = {
    users: [],
    pageSize: 15,
    totalUsers: 54,
    currentPage: 1,
    isFetching: false,
    searchString: "",
    usersSortType: 0,
    usersSortTypeArray: ["Сортировка", "По имени", "По фамилии", "По nickname", "По email", "По должности", "По дате рождения", "По последней активности", "По дате регистрации"],
    isAsc: true
}

const usersReduser = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.users
            }
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.currentPage
            }
        case SET_TOTAL_COUNT:
            return {
                ...state,
                totalUsers: action.totalUsers
            }
        case TOGGLE_IS_FTCHING:
            return {
                ...state,
                isFetching: action.isFetching
            }
        case UPDATE_SEARCH_STRING:
            return {
                ...state,
                searchString: action.searchString
            }
        case UPDATE_USERS_SORT_TYPE:
            return {
                ...state,
                usersSortType: action.usersSortType
            }
        case SET_SEARCH_STRING:
            return {
                ...state,
                searchString: action.searchString
            }
        case SET_ASC:
            return {
                ...state,
                isAsc: action.isAsc
            }
        default:
            return state;
    }
}

export const setSearchString = (searchString) => {
    return {
        type: SET_SEARCH_STRING,
        searchString: searchString
    };
}

export const setUsers = (users) => {
    return {
        type: SET_USERS,
        users: users
    };
}

export const setCurrentPage = (numberPage) => {
    return {
        type: SET_CURRENT_PAGE,
        currentPage: numberPage
    };
}

export const setTotalUsersCount = (totalUsers) => {
    return {
        type: SET_TOTAL_COUNT,
        totalUsers: totalUsers
    };
}

export const toggleIsFetching = (isFetching) => {
    return {
        type: TOGGLE_IS_FTCHING,
        isFetching: isFetching
    };
}

export const updateSearchString = (searchString) => {
    return {
        type: UPDATE_SEARCH_STRING,
        searchString: searchString
    };
}

export const updateUsersSortType = (usersSortType) => {
    return {
        type: UPDATE_USERS_SORT_TYPE,
        usersSortType: usersSortType
    };
}

export const setAsc = (isAsc) => {
    return {
        type: SET_ASC,
        isAsc: isAsc
    };
}

export default usersReduser;