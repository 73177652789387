import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAdmin } from '../../asyncAction/admin';
import AdminTable from './AdminTable/AdminTable';
import './AdminPage.css'
import { Button } from 'react-bootstrap';
import AdminCreateModal from './AdminCreateModal/AdminCreateModal';
import { setCreateAdminEmail, setCreateAdminLastName, setCreateAdminName, setCreateAdminPassword, setCreateAdminType } from '../../redux/createAdmin-reducer';

const AdminPage = () => {
    const dispatch = useDispatch();
    const admins = useSelector(state => state.adminPage.admins);

    useEffect(() => {
        dispatch(getAdmin());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        dispatch(setCreateAdminName(""));
        dispatch(setCreateAdminLastName(""));
        dispatch(setCreateAdminEmail(""));
        dispatch(setCreateAdminPassword(""));
        dispatch(setCreateAdminType(1));
        setShow(true);
    }

    return (
        <div className='admin__page__content'>
            <Button variant="primary" onClick={handleShow}>
                Добавить нового администратора
            </Button>
            <AdminCreateModal show={show} handleClose={handleClose}/>
            <div>
                <AdminTable admins={admins} />
            </div>
        </div>
    )
}

export default AdminPage
