import { setAdmin } from "../redux/admin-reducer"
import { setEmail, setFirstName, setFullName, setIsLoading, setLastName } from "../redux/adminEdit-reducer"
import requestAdmin from "../requests/requestAdmin"

export const getAdmin = () => {
    return async dispatch => {
        await requestAdmin.get(`Account/Admin`)
            .then(data => dispatch(setAdmin(data.data)))
    }
}


export const getEditAdmin = (id) => {
    return async dispatch => {
        await requestAdmin.get(`Account/Admin/` + id)
            .then(data => {
                dispatch(setFirstName(data.data.firstName));
                dispatch(setLastName(data.data.lastName));
                dispatch(setFullName(data.data.fullName));
                dispatch(setEmail(data.data.email));
            });
    }
}

export const resetPassword = (email) => {
    return async dispatch => {
        dispatch(setIsLoading(true));
        await requestAdmin.put(`Account/ResetPassword/`, {
            email: email
        })
            .then(data => {
                dispatch(setIsLoading(false));
                alert("Пароль сброшен!");
            });
    }
}

export const createAdmin = (name, lastName, email, password, type) => {
    return async dispatch => {
        requestAdmin.post('/Account/RegisterNewAdmin', {
            firstName: name,
            lastName: lastName,
            email: email,
            password: password,
            passwordConfirm: password,
            typeRegister: type
        }).then(res => {
            alert("Письмо отправлено");
            dispatch(getAdmin());
        })
    }
}

export const saveChange = (name, lastName, email, id) => {
    return async dispatch => {
        requestAdmin.put('/Account', {
            userId: id,
            firstName: name,
            lastName: lastName,
            email: email,
        }).then(res => {
            alert("Сохранено");
            dispatch(getAdmin());
        })
    }
}

export const deleteAdmin = (id) => {
    return async dispatch => {
        requestAdmin.delete('/Account?UserId=' + id)
            .then(dispatch(getAdmin()))
    }
}

export const getCsvUsers = async () => {
    try {
        const response = await requestAdmin.get('Admin/CsvUsers', {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users.csv');
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error(error);
    }
};