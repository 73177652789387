import { connect } from "react-redux";
import { setSpPassword, setSpPasswordConfirm } from "../../redux/setPassword-reducer";
import SetPassword from "./SetPassword";

let mapStateToProps = (state) => {
    return {
        password: state.setPasswordPage.spPassword,
        passwordConfirm: state.setPasswordPage.spPasswordConfirm
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setPassword: (password) => {
            dispatch(setSpPassword(password));
        },
        setPasswordConfirm: (passwordConfirm) => {
            dispatch(setSpPasswordConfirm(passwordConfirm));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);