import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  cancelHistory, getHistory } from '../../asyncAction/history';
import './History.css'

const History = () => {
    const dispatch = useDispatch();
    const history = useSelector(state => state.historyPage.history)
    useEffect(() => {
        dispatch(getHistory());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])
    
    let getDate = (date) => {
        if (date == null) {
            return "";
        }

        let dateWithoutSec = date.split('T')[0];
        let time = date.split('T')[1];
        return dateWithoutSec.split('-')[2]
            + '.'
            + dateWithoutSec.split('-')[1]
            + '.'
            + dateWithoutSec.split('-')[0]
            + ' '
            + time.split('.')[0].split(':')[0]
            + ':'
            + time.split('.')[0].split(':')[1];
    }

    let navigate = useNavigate();
    const routeChange = (userId) =>{
      let path = "/user/" + userId;
      navigate(path);
    }

    return (
        <div className='history_content'>
            <div className='history_title'>История изменений</div>
            <Table hover size="sm">
                <thead>
                    <tr>
                        <th>Пользователь</th>
                        <th>Измение</th>
                        <th>Старое значение</th>
                        <th>Новое значение</th>
                        <th>Дата изменения</th>
                        <th>Отмена изменения</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        history.map(h =>
                            <tr key={h.id}>
                                <td className='user-ref' onClick={(e) => {routeChange(h.userId)}}>{h.userFullName}</td>
                                <td className='user-ref' onClick={(e) => {routeChange(h.userId)}}>{h.field}</td>
                                <td className='user-ref' onClick={(e) => {routeChange(h.userId)}}>{h.oldValue}</td>
                                <td className='user-ref' onClick={(e) => {routeChange(h.userId)}}>{h.newValue}</td>
                                <td className='user-ref' onClick={(e) => {routeChange(h.userId)}}>{getDate(h.date)}</td>
                                <td><button onClick={(e) => {  dispatch(cancelHistory(h.id)); }}>Отменить</button></td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default History