import requestAdmin from "../../requests/requestAdmin";
import React from 'react'

const SetPassword = (props) => {
    const url = new URL(window.location.href);
    const userId = url.searchParams.get('UserId');
    const code = url.searchParams.get('Code');

    let onPasswordChange = (e) => {
        let body = e.target.value;
        props.setPassword(body);
    }

    let onPasswordConfirmChange = (e) => {
        let body = e.target.value;
        props.setPasswordConfirm(body);
    }

    function handleSubmit(e) {
        e.preventDefault();
        requestAdmin.put('/Account/SetPassword', {
            userId: userId,
            code: code,
            password: props.password,
            passwordConfirm: props.password,
        }).then(res => {
            alert("Письмо отправлено");
        })
    }

    return (
        <div className="register-form__container form__container">
            <h2 className="register-form__heading">Установите пароль</h2>
            <form onSubmit={handleSubmit} className='register-form__form'>
                <input type='password'
                    id='password'
                    name='password'
                    value={props.password}
                    onChange={onPasswordChange}
                    placeholder='Пароль'
                    className="register-form__input">
                </input>
                <input type='password'
                    id='passwordConfirm'
                    name='passwordConfirm'
                    value={props.passwordConfirm}
                    onChange={onPasswordConfirmChange}
                    placeholder='Подтвердите пароль'
                    className="register-form__input">
                </input>
                <button onClick={() => handleSubmit} className="login-form__btn">Отправить</button>
            </form>
        </div>
    )
}

export default SetPassword
